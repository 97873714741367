import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const TradesCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				lou: file(relativePath: { eq: "testimonials/lou.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				alan: file(relativePath: { eq: "testimonials/Alan-Norton.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				claire: file(relativePath: { eq: "testimonials/claire-white.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Trades | Business & Executive Coaching in London";
	const description =
		"Are you eager to grow within the trades world? That's a huge task, right? You're in need of a partner who really grasps the ins and outs - the victories and challenges, the day-to-day grind.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const lou = data.lou.childImageSharp.gatsbyImageData;
	const alan = data.alan.childImageSharp.gatsbyImageData;
	const claire = data.claire.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Trades",
				item: {
					url: `${siteUrl}/industries/trades`,
					id: `${siteUrl}/industries/trades`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/trades`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Trades business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business and Executive Coach For Trades"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Are you eager to grow within the trades world? That's a huge
								task, right? You're in need of a partner who really grasps the
								ins and outs - the victories and challenges, the day-to-day
								grind. Someone who has been there and done it, to help you map
								out your ambitions and make them a reality, and stand by your
								side through those tough calls. Your ideal coach is someone who
								really connects with you, has a deep knowledge of the trades,
								and gets the unique hurdles you encounter, whether on the job
								site or behind the scenes trying to grow your business.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Utilise the power of a business and executive coach
							</h3>
							<p>
								With nearly 40 years in business myself, I've helped ambitious
								business owners from all sorts of trades either kick off their
								businesses or take them to new heights.
							</p>
							<p>
								Feel like you're on the hunt for something more? Maybe it's a
								deeper sense of achievement in your work, or perhaps you're
								itching for a new challenge that sparks that fire for your trade
								all over again.
							</p>
							<p>
								Why not explore what we can achieve together? A little bit of
								coaching might be just the spark you need to boost your
								business. Let's jump in and discover where this business
								coaching adventure can lead us.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								Where can you take your trades business
							</h3>

							<p>
								Imagine you've got a pair of special glasses that let you see
								into the future of your trade. What if you caught a glimpse of
								yourself:
							</p>
							<ul className="pl-4">
								<li>Bright with confidence, truly owning your craft?</li>
								<li>
									Polishing those leadership and communication skills that make
									all the difference on the job site and with growing your team?
								</li>
								<li>
									Making decisive, confident choices for the future of your
									business, no second-guessing?
								</li>
								<li>
									Finding your love for the trade, feeling that original spark
									that got you started?
								</li>
								<li>
									Or perhaps you're facing down new challenges with a grin,
									ready to tackle them head-on?
								</li>
							</ul>
							<p>
								Consider how your professional life could transform if these
								weren't just dreams, but became your everyday reality.
							</p>
							<p>
								So, what's holding you back from taking your trade business to
								the next level?
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Is there something holding you back?</h2>
							<ul className="pl-4">
								<li>
									Feeling swamped with the day-to-day grind of your trade,
									making critical calls on the job, and handling a multitude of
									tasks?
								</li>
								<li>
									Transitioning from being a hands-on tradesperson to running
									your own business with plans to keep it growing?
								</li>
								<li>
									Finding it tough to pinpoint your strengths and areas for
									development?
								</li>
								<li>
									In need of some clear direction on your path to growing your
									trades business?
								</li>
								<li>
									Or maybe you're at a crossroads, wondering which way to turn
									next?
								</li>
							</ul>
							<p>
								In the ever-changing landscape of the trades, we're constantly
								faced with challenges that put our skills to the test. But it's
								the way we tackle these obstacles and learn from them that will
								shape and boost our business in the trades.
							</p>
							<p>
								That's where I come in with my business and executive coaching
								services, tailored specifically for tradespeople looking to
								kickstart or boost their business. My coaching offers the
								insights, strategies, and support you need to overcome the
								challenges of business leadership in the trades, allowing you to
								make powerful decisions for your business and your future. No
								business will grow without hard work and dedication, which a
								business coach can help you with.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Achieve new successes with business and executive coaching
							</h2>
							<p>
								As a business coach working with tradespeople like you, my goal
								is to help you harness the unique opportunities within your
								field. I understand that your schedule can look busy, and that's
								why our coaching sessions are designed to fit smoothly into your
								work life, accommodating even the busiest of days.
							</p>
							<p>
								In our time together, we'll focus on key areas crucial to both
								your trades business and personal growth:
							</p>
							<ul className="pl-4">
								<li>
									New Skills and Personal Growth: Growing your trade business
									while also focusing on your own ambitions and well-being.
								</li>
								<li>
									Leadership Development: Building leadership qualities that
									inspire your team and lead to significant achievements in your
									projects.
								</li>
								<li>
									Smooth Business Operations: Tackling the challenges of
									managing your trade business to ensure everything runs like
									clockwork.
								</li>
								<li>
									Career Strategy: Evaluating your career path, identifying
									opportunities for advancement and fulfilment.
								</li>
							</ul>
							<p>
								What sets my business coaching apart is the customised approach
								I take. The sessions are built around your unique hurdles and
								goals, offering the insight, advice, and strategies you need to
								succeed and grow in your trade and make a real impact on your
								team and customers.
							</p>
							<p>
								Why not start this adventure with a conversation? Let's discuss
								how we can achieve your goals together.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">How a business coach can help you</h2>
							<p>
								Throughout my journey in the trades, I've seen it all. The highs
								of successful projects and the lows.
							</p>
							<p>
								I've got a toolkit that's tailor-made for supporting ambitious
								business owners in the trades. Whether your team is hammering
								nails or laying pipes, I'm here to offer a unique blend of
								guidance, drawn from nearly four decades of making it work as a
								business owner, to help you navigate your path to success with
								confidence.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Positive feedback from my coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Louise McIntyre. Printingprogress Ltd"
						review="Jason is a breath of fresh air and an incredibly talented business coach. If you are looking for someone to inspire you and help you to see how you can grow both your self-confidence and business then look no further. I have had many business coaches that work to a model that has never quite suited me or my business, but Jason is different he adapts his expertise and meets you exactly where he makes the most impact. Highly Recommended."
						linkedIn="https://www.linkedin.com/in/lou-mcintosh-712ab920"
						profilePic={lou}
					/>

					<HorizontalTestimonial
						name="Alan Norton. Managing Director. Equilibrium Building Solutions Ltd"
						review="For those of you business owners out there who think you can’t afford a business coach, think again! You are probably finding yourself in some financial difficulties at present or need a new direction. Can I really afford him, I hear you ask.? The very reason you’re running your own business, is that you want to do it your way, a leader not a follower. We all struggle from time to time and lose our way. I urge you to contact Jason. This is a man who has ‘walked the walk’! He will listen to your current situation, your goals and dreams. He will understand you and create an action plan for your success. Always there to offer you support as and when you need it. I took the leap! In just 3 months my business has grown, I now know where I’m going and where I want to be. I am attracting specific enquiries and as such I’m doing the work I love. It’s no different than using a fitness coach but for your business. Trust me, you’ll see the results"
						linkedIn="https://www.linkedin.com/in/alan-norton-807661aa/"
						profilePic={alan}
					/>

					<HorizontalTestimonial
						showLinkedIn="d-none"
						name="Claire White – Pugs in a Tub Dog Grooming, Eastbourne"
						review="It is such a weight off my mind to have Jason’s ongoing support and encouragement to grow my already successful business. He has re-lit my passion for my work and my confidence has grown enormously. Firstly, I found the personality profiling exercise absolutely fascinating.  Why? Because it helped me to understand why I do things in a certain way.  And, how I can try different approaches to overcome everyday challenges. It’s obvious that Jason understands business.  Also, it’s great to bounce my ideas around to come to conclusions needed. Jason keeps me on track and I’m more relaxed because I know I am going in the right direction. Our sessions are a combination of fun and serious moments in just the right balance. If you are a small business owner like me, I suggest you make time to meet Jason for a coffee. I’m glad I did."
						profilePic={claire}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a discovery call today</h3>
							<p>
								Being in the trades can be all-consuming, leaving little time to
								reflect on your own growth and the direction of your business.
								How about we jump on a discovery call? It's the perfect
								opportunity for us to dive into how business coaching might just
								be the breakthrough you need.
							</p>
							<p>
								P.S. No matter how confident you are with your trade, the
								reality is, running a business solo has its limits. That's where
								I step in - your source of straightforward feedback, strategic
								goal-setting, and that extra push when you face tough decisions.
								Let's join forces and tap into your business's full potential.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default TradesCoachingPage;
